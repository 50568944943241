import "whatwg-fetch"
import "@webcomponents/template"
import "@webcomponents/shadydom"
import "formdata-polyfill"
import "classlist-polyfill"
import "proxy-polyfill/proxy.min"

// Loaded in a script tag with the "nomodule" attribute, basically only targeting IE

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

if (!Element.prototype.toggleAttribute) {
  Element.prototype.toggleAttribute = function (name, force) {
    if (force !== void 0) force = !!force

    if (this.hasAttribute(name)) {
      if (force) return true

      this.removeAttribute(name)
      return false
    }
    if (force === false) return false

    this.setAttribute(name, "")
    return true
  }
}
